/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/gsap@2.0.2/src/minified/TweenMax.min.js
 * - /npm/gsap@2.0.2/src/minified/utils/Draggable.min.js
 * - /npm/gsap@2.0.2/src/minified/plugins/ScrollToPlugin.min.js
 * - /npm/scrollmagic@2.0.6/scrollmagic/minified/ScrollMagic.min.js
 * - /npm/scrollmagic@2.0.6/scrollmagic/minified/plugins/animation.gsap.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
